<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 天气情况 -->
      <div class="content-item weather-conditions">
        <div class="content-title">
          <span>天气情况</span>
        </div>
        <div class="content-charts">
          <div class="weather-info" :title="warnTxt">
            <span>{{ warnTxt }}</span>
          </div>
          <div class="main-conditions">
            <div class="single-conditions" v-if="nowWeather.icon">
              <div class="icon">
                <img
                  :src="
                    require(`../../../assets/img/large-screen/76px/${nowWeather.icon}.png`)
                  "
                  alt=""
                />
              </div>
              <span class="num">{{ nowWeather.temp }}°C</span>
              <span>{{ nowWeather.text }}</span>
              <span>{{ nowWeather.windDir }} {{ nowWeather.windScale }}级</span>
            </div>
            <div class="some-conditions">
              <div
                v-for="(ele, idx) in thrWeather"
                v-show="idx > 0 && idx < 4"
                :key="idx"
                class="some-item"
              >
                <span>{{ $.formatDateToDayNum(new Date(ele.fxDate)) }}</span>
                <span>{{ $.formatDate(new Date(ele.fxDate), 'MM-dd') }}</span>
                <div class="icon">
                  <img
                    :src="
                      require(`../../../assets/img/large-screen/42px/${ele.iconDay}.png`)
                    "
                    alt=""
                  />
                </div>
                <span class="num">{{ ele.tempMax }}～{{ ele.tempMin }}°C</span>
                <span>{{ ele.textDay }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 水体监测 -->
      <div class="content-item aquatic-monitoring">
        <div class="content-title">
          <span>水体监测</span>
        </div>
        <div class="content-charts">
          <swiper :options="swiperOption">
            <swiper-slide v-for="ele in quotaList" :key="ele.boxId">
              <div class="charts-item" @click="clickIdx(ele)">
                <div class="item-title">{{ ele.boxName }}</div>
                <div class="item-content">
                  <div class="ls-box">
                    <div class="txts">
                      溶氧值
                      <span>{{ ele.oxygen }}mg/L</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :show-text="false"
                        :percentage="ele.oxygen_percent || 0"
                      ></el-progress>
                    </div>
                  </div>
                  <div class="ls-box">
                    <div class="txts">
                      pH
                      <span>{{ ele.ph }}</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :percentage="ele.ph_percent || 0"
                        :show-text="false"
                      ></el-progress>
                    </div>
                  </div>
                  <div class="ls-box">
                    <div class="txts">
                      水温
                      <span>{{ ele.water }}°C</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :percentage="ele.water_percent || 0"
                        :show-text="false"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 网箱1 -->
      <div class="content-item box-conditions">
        <div class="content-title" style="cursor: pointer;" @click="openHistoryDialog()">
          <span>{{ boxName }}</span>
        </div>
        <div class="content-charts">
          <div
            id="box-conditions-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.waterMonitorNumber || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水质检测/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="+infoData.waterCamera || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水上摄像头/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.underwaterCamera || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水下摄像头/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.underwaterRobot || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水下机器人/台</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 全产业链可视化管理 -->
      <div class="content-item video-monitoring">
        <div class="content-title">
          <span>全产业链可视化管理</span>
        </div>
        <div class="content-charts">
          <div
            :class="[
              videoList && videoList.length == 0
                ? 'video-not-found'
                : 'video-box',
            ]"
          >
            <Video
              v-if="showVideo"
              :videoUrl="videoItem && videoItem.ezopenUrl"
              :token="videoItem && videoItem.accessToken"
              :videoCode="videoItem && videoItem.videoCode"
              :videoType="videoItem && videoItem.facilityCodeType"
            ></Video>
            <div class="video-mark" @click="dialogFormVisible = true"></div>
          </div>
          <div class="video-options">
            <div class="top-icon">
              <img
                src="../../../assets/img/large-screen/icon-top-arrow.png"
                alt
                @click="videoSwiper('down')"
              />
            </div>
            <div class="main-ls" ref="video_list">
              <div
                class="main-ls-context"
                :style="`margin-top: ${(curIdx * itemheight) / 5}px`"
              >
                <div
                  v-for="(ele, i) in videoList"
                  :key="i"
                  :class="['ls-item', { active: ele.selected }]"
                  @click="videoView(i)"
                >
                  {{ ele.boxName && ele.boxName.slice(0, 4) }}
                </div>
              </div>
            </div>
            <div class="bottom-icon">
              <img
                src="../../../assets/img/large-screen/icon-bottom-arrow.png"
                alt
                @click="videoSwiper('up')"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 溯源分析 -->
      <div class="content-item traceability-analysis">
        <div class="content-title">
          <span>平台点击量分析</span>
        </div>
        <div class="content-charts">
          <div
            id="traceability-analysis-send"
            style="width: 100%; height: 100%"
            v-loading="!infoData.issueNumberList.length"
            element-loading-background="transparent"
          ></div>
        </div>
      </div>

      <!-- 预警信息 -->
      <div class="content-item warning-information">
        <div class="content-title">
          <span>预警信息</span>
        </div>
        <div class="content-charts">
          <div class="ls-title" v-if="queryWarning.length">
            <span>塘口</span>
            <span>设备</span>
            <span>预警类型</span>
            <span>预警时间</span>
          </div>
          <div class="chart-item" v-if="queryWarning.length">
            <swiper :options="swiperOption01">
              <swiper-slide v-for="(ele, idx) in queryWarning" :key="idx">
                <div class="ls-item">
                  <span>{{ ele.boxName }}</span>
                  <span>{{ ele.facilityCode }}</span>
                  <span>{{ ele.warnInfo }}</span>
                  <span v-html="ele.warnTime.split(' ').join('<br />')"></span>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <p v-else class="no-data">一切正常，无预警信息</p>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>

    <el-dialog
      title="模块点击量统计"
      :visible.sync="dialogChartVisible"
      :width="app.fontHandle(1400, 'px')"
      top="10vh"
      v-if="dialogChartVisible"
    >
      <div class="content-charts" id="click-num-chart" style="padding: 0;height: 9rem;background-color: #002b4b;">
      </div>
    </el-dialog>

    <el-dialog
      :title="this.boxName + '指标历史数据'"
      :visible.sync="dialogHistoryVisible"
      :width="app.fontHandle(1400, 'px')"
      top="10vh"
      v-if="dialogHistoryVisible"
    >
      <div>        
        <el-date-picker
          v-model="historyTargetDate"
          type="daterange"
          @change="queryWaterDataChart()"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="content-charts" id="history-target-chart" style="padding: 0;height: 9rem;background-color: #002b4b;">
      </div>
    </el-dialog>

    <el-dialog
      title="实时监控"
      :width="app.fontHandle(882, 'px')"
      top="30vh"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
    >
      <div class="content-charts">
        <div
          :class="[
            videoList && videoList.length == 0
              ? 'video-not-found'
              : 'video-box',
          ]"
          v-loading="!showVideo"
          element-loading-background="transparent"
        >
          <VideoPpopup
            :videoUrl="videoItem && videoItem.ezopenUrl"
            :token="videoItem && videoItem.accessToken"
            :videoCode="videoItem && videoItem.videoCode"
            :videoType="videoItem && videoItem.facilityCodeType"
          ></VideoPpopup>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue'
import { Progress, DatePicker } from 'element-ui'
Vue.use(Progress)
Vue.use(DatePicker)

import { getWeather, getWarning } from '../modules/weather-api'
import CountFlop from '../../../components/count-to-component.vue'

import * as $echarts from 'echarts'
import {LogKeyName} from '../modules/static.js'
import CountTo from 'vue-count-to'
import Video from './video.vue'
import VideoPpopup from './video-popup.vue'
import moment from 'moment'
export default {
  name: 'digital-park',
  components: {
    CountFlop,
    CountTo,
    swiper,
    swiperSlide,
    Video,
    VideoPpopup,
  },
  inject: ['app'],
  data() {
    return {
      historyTargetDate: '',
      dialogFormVisible: false,
      dialogChartVisible: false,
      dialogHistoryVisible: false,
      infoData: {
        issueNumberList: [],
        queryCountByYearList: [],
      },
      queryWarning: [],
      boxName: '', // 当前显示的下标
      boxId: '', // 当前显示的下标
      swiperOption01: {
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: 4,
        autoplay: {
          disableOnInteraction: false,
          delay: 1000, //1秒切换一次
        },
        // mousewheel: true,
        slidesPerView: 4,
        direction: 'vertical',
      },
      swiperOption: {
        mousewheel: {
          thresholdTime: 200,
        },
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: this.app.fontHandle(30) - 1,
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 1000, //1秒切换一次
        // },
        // mousewheel: true,
        slidesPerView: 3,
        // loop: true,
        direction: 'vertical',
      },
      nowWeather: {}, // 实时天气
      thrWeather: [], // 三天天气
      warnTxt: '',
      params: {},
      map: null,
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      cityList: [],
      active: -1,
      conn: true,
      quotaList: [], // (水体监测)指标列表
      // 网箱1
      conditions: {
        idName: 'box-conditions-chart',
        legend: {
          data: ['溶氧', 'pH', '水温'],
        },
        series: {
          // 水温
          waterData: {
            name: '水温',
            data: [
              22.92, 22.92, 22.92, 22.92, 22.91, 22.92, 22.92, 22.91, 22.91,
              22.92, 22.91, 22.91, 22.91, 22.91, 22.92, 22.91, 22.93, 22.92,
              22.94, 22.93, 22.94, 22.94, 22.95, 22.94, 22.95, 22.95, 22.95,
              22.95, 22.96, 22.95, 22.97, 22.96, 22.97, 22.97, 22.98, 22.97,
              22.98, 22.98, 22.98, 22.98, 22.99, 22.98, 22.98, 22.99, 22.98,
              22.98, 22.98, 22.98, 22.98, 22.98, 22.97, 22.98, 22.97, 22.97,
              22.96, 22.97, 22.96, 22.96, 22.96, 22.96, 22.96, 22.96, 22.96,
              22.96, 22.96, 22.96, 22.96, 22.96, 22.97, 22.96, 22.96, 22.97,
              22.97, 22.96, 22.97, 22.97, 22.97, 22.97, 22.98, 22.97, 22.98,
              22.98, 22.98, 22.98, 22.99, 22.98, 22.99, 22.99, 23, 22.99, 23,
              23, 23,
            ],
          },
          // 溶氧
          oxData: {
            name: '溶氧',
            data: [
              7.48, 7.48, 7.49, 7.48, 7.49, 7.49, 7.5, 7.49, 7.53, 7.5, 7.53,
              7.53, 7.56, 7.53, 7.58, 7.56, 7.59, 7.58, 7.63, 7.59, 7.59, 7.63,
              7.59, 7.59, 7.6, 7.59, 7.62, 7.6, 7.64, 7.62, 7.63, 7.64, 7.67,
              7.63, 7.67, 7.67, 7.67, 7.67, 7.67, 7.67, 7.7, 7.67, 7.69, 7.7,
              7.75, 7.69, 7.75, 7.75, 7.76, 7.75, 7.79, 7.76, 7.83, 7.79, 7.88,
              7.83, 7.91, 7.88, 7.96, 7.91, 7.96, 7.96, 7.97, 7.96, 7.99, 7.97,
              7.99, 7.99, 8.02, 7.99, 8.03, 8.02, 8.02, 8.03, 8.05, 8.02, 8.04,
              8.05, 8.04, 8.04, 8.09, 8.09, 8.08, 8.09, 8.08, 8.08, 8.09, 8.08,
              8.09, 8.09, 8.09, 8.09,
            ],
          },
          // ph
          phData: {
            name: 'pH',
            data: [
              8.08,
              8.08,
              8.08,
              8.08,
              8.08,
              8.08,
              8.09,
              8.08,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.08,
              8.09,
              8.09,
              8.08,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.09,
              8.1,
              8.09,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.1,
              8.11,
              8.1,
              8.12,
              8.11,
              8.12,
              8.12,
              8.12,
              8.12,
              8.11,
              8.12,
              8.12,
              8.11,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              8.13,
              8.13,
              8.12,
              8.13,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
              8.12,
            ],
          },
        },
        xAxis: {
          data: [
            '11:05',
            '11:06',
            '11:07',
            '11:08',
            '11:09',
            '11:10',
            '11:11',
            '11:12',
            '11:13',
            '11:14',
            '11:15',
            '11:16',
            '11:17',
            '11:18',
            '11:19',
            '11:20',
            '11:21',
            '11:22',
            '11:23',
            '11:24',
            '11:25',
            '11:26',
            '11:27',
            '11:28',
            '11:29',
            '11:30',
            '11:31',
            '11:32',
            '11:33',
            '11:34',
            '11:35',
            '11:36',
            '11:37',
            '11:38',
            '11:39',
            '11:40',
            '11:41',
            '11:42',
            '11:43',
            '11:44',
            '11:45',
            '11:46',
            '11:47',
            '11:48',
            '11:49',
            '11:50',
            '11:51',
            '11:52',
            '11:53',
            '11:54',
            '11:55',
            '11:56',
            '11:57',
            '11:58',
            '11:59',
            '12:00',
            '12:01',
            '12:02',
            '12:03',
            '12:04',
            '12:05',
            '12:06',
            '12:07',
            '12:08',
            '12:09',
            '12:10',
            '12:11',
            '12:12',
            '12:13',
            '12:14',
            '12:15',
            '12:16',
            '12:17',
            '12:18',
            '12:19',
            '12:20',
            '12:21',
            '12:22',
            '12:23',
            '12:24',
            '12:25',
            '12:26',
            '12:27',
            '12:28',
            '12:29',
            '12:30',
            '12:31',
            '12:32',
            '12:33',
          ],
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            showMaxLabel: true,
            rotate: 45,
            interval: null,
          },
        },
        yAxis: {},
      },
      historyTarget: {
      },
      // 月查询次数
      traceability: {
        idName: 'traceability-analysis-search',
        title: {
          text: '月查询次数',
        },
        xAxis: {
          data: [
            '1月'
          ],
        },
        series: {
          name: '次数',
          data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30],
        },
      },

      // 点击量
      clickNumChart: {
        idName: 'click-num-chart',
        title: {
          text: '点击量',
        },
        legend: {
          data: ['点击量'],
        },
        xAxis: {
          data: [
            '12月',
          ],
        },
        series: [
          [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30]
        ],
      },
      // 月发放溯源码数量
      traceabilitySend: {
        idName: 'traceability-analysis-send',
        title: {
          text: '月查询次数',
        },
        legend: {
          data: ['月查询次数'],
        },
        xAxis: {
          data: [
            '12月',
          ],
        },
        series: [
          [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30]
        ],
      },
      videoList: null, // 视频列表
      videoUrl: null, // 视频路径
      videoItem: null, // 视频对象
      showVideo: true, // 是否展示视频
      videoSwiperCount: 0, // 视频上下点击滑动计数
      itemheight: 0,
      curIdx: 0,
    }
  },
  computed: {},
  methods: {
    clickIdx(box) {
      this.boxName = box.boxName
      this.boxId = box.boxId
      // 水体监测详情
      this.$axios(
        `/zhanjiang-dataview/viewDigitalPark/queryWaterInfoByBoxId/${box.boxId}`
      )
        .then((res) => {
          // 有数据
          if (res.data && res.data.length > 0) {
            const conditions = JSON.parse(JSON.stringify(this.conditions))
            // 获取当前时间
            const nowTime = moment().format('YYYY-MM-DD HH:mm')
            // 指标设置
            // 获取一个过去几小时数据都为''的数组
            const dataArr = this.createEmptyDataArrByHour(nowTime, 24)
            // 遍历返回数据中的list第一项
            res.data &&
              res.data.forEach((re) => {
                const res_date = (re.updateTime = re.updateTime.slice(5, 16))
                dataArr.forEach((res2) => {
                  const res2_date = res2.updateTime.slice(5, 16)
                  if (res_date === res2_date) {
                    res2['isData'] = true
                    res2.oxygen = re.oxygen
                    res2.ph = re.ph
                    res2.water = re.water
                  }
                })
              })
            // 遍历数据，对为''的数据做一下处理
            // 如果它前3项不为''，那么赋值于它
            dataArr.forEach((re, index) => {
              if (re.oxygen === '') {
                for (let i = index - 1; i >= index - 3; i--) {
                  if (
                    dataArr[i] &&
                    dataArr[i].oxygen !== '' &&
                    dataArr[i].isData
                  ) {
                    re.oxygen = dataArr[i].oxygen
                    re.ph = dataArr[i].ph
                    re.water = dataArr[i].water
                  }
                }
              }
            })
            conditions.series.waterData.data,
              conditions.series.oxData.data,
              conditions.series.phData.data,
              ((conditions.series.waterData.data = []),
              (conditions.series.oxData.data = []),
              (conditions.series.phData.data = []),
              (conditions.xAxis.data = []))

            dataArr.forEach((re) => {
              conditions.series.waterData.data.push(re.water)
              conditions.series.oxData.data.push(re.oxygen)
              conditions.series.phData.data.push(re.ph)
              conditions.xAxis.data.push(re.updateTime.slice(11, 16))
            })
            console.log('水数据', conditions.xAxis.data)
            conditions.xAxis.axisLabel.interval = dataArr.length / 8 - 1
            this.seedlingBreedingChart(conditions)
          } else {
            // 无数据, 使用默认参数
            this.seedlingBreedingChart(this.conditions)
          }
        })
        .catch(() => {})
    },
    // 历史数据指标折线图
    historyTargetChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(16, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
          showMaxLabel: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
              rotate: 45,
              interval: 1439,

              formatter: (value) => {
                return value ? value.split(' ')[0] : value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.waterData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#F06CFA',
            },
            data: obj.series.waterData.data,
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 100, 235, 0.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 100, 235, 0.24)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.phData.name,
            data: obj.series.phData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3CB8FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#3CB8FF',
            },
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.oxData.name,
            data: obj.series.oxData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#9287FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#9287FF',
            },
            smooth: true,
            symbolSize: 0,
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图
    seedlingBreedingChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(16, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.waterData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#F06CFA',
            },
            data: obj.series.waterData.data,
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 100, 235, 0.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 100, 235, 0.24)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.phData.name,
            data: obj.series.phData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3CB8FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#3CB8FF',
            },
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.oxData.name,
            data: obj.series.oxData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#9287FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#9287FF',
            },
            smooth: true,
            symbolSize: 0,
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    traceabilityAnalysisChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let seriescolor = ['#5B49FF', '#F55AFB']
      let series = obj.legend.data.map((ele, idx) => {
        return {
          name: ele,
          type: 'line',
          itemStyle: {
            normal: {
              color: seriescolor[idx],
            },
          },
          lineStyle: {
            width: 2.5,
            color: seriescolor[idx],
          },
          data: obj.series[idx],
          smooth: true,
          symbolSize: 0,
        }
      })
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series,
      }
      chart.setOption(option)
    },
    // 选择视频展示
    videoView(i) {
      this.showVideo = false
      this.videoList.forEach((re, j) => {
        re.selected = j == i ? (true, (this.videoItem = re)) : false
      })
      setTimeout(() => {
        this.showVideo = true
      }, 0)
    },
    // 视频列表上下滑动
    videoSwiper(btn) {
      this.itemheight = this.$refs.video_list.clientHeight // 列表元素高
      let curIdx = this.curIdx
      // 往上滑动
      if (btn == 'up') {
        curIdx--
      } else {
        curIdx++
      }
      // console.log(this.videoList.length, this.curIdx)
      if (
        this.videoList.length <= 5 ||
        !(curIdx >= -this.videoList.length + 5 && curIdx <= 0)
      ) {
        return
      }
      this.curIdx = curIdx
    },
    /**
     * @brief: 生成过去24h/48h/72h的空数据数组
     * @author: Scarlett Xiao
     * @Date: 2021-10-19 17:57:17
     * @param {*} nowTime 当前时间(YYYY-MM-DD HH:mm)
     * @param {number} hour 多少小时(24)
     */
    createEmptyDataArrByHour(nowTime, hour) {
      const count = hour * 60 // 过去多少小时乘60分钟
      const DATA = []
      DATA.push({
        updateTime: nowTime,
        oxygen: '',
        ph: '',
        water: '',
        isData: false,
      })
      while (DATA.length < count) {
        const date = new Date(DATA[0].updateTime.replace(/-/g, '/'))
        // 获取前一分钟的时间
        const prevMinutes = moment(date.getTime())
          .subtract(1, 'minutes')
          .format('YYYY-MM-DD HH:mm')
        DATA.unshift({
          updateTime: prevMinutes,
          oxygen: '',
          ph: '',
          water: '',
          isData: false,
        })
      }
      return DATA
    },
    getLog(month) {
      this.$axios('/zhanjiang-admin/systemLog/monthDetail?queryDate=' + month + '-1')
        .then((res) => {
          // console.log('res', res)
          const data = res.data
          this.clickNumChart.xAxis.data = []
          this.clickNumChart.series[0] = []
          data.forEach((item) => {
            // console.log('item', item, LogKeyName[item.moduleType])
            this.clickNumChart.xAxis.data.push(LogKeyName[item.moduleType])
            this.clickNumChart.series[0].push(item.clickCount)
          })
          this.$nextTick(() => {

            this.clickCountChart(this.clickNumChart)
          })

        })
        .catch(() => {})
    },

    // 折线图
    aquaticTrendChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['点击量'],
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(30, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: '点击量',
            type: 'bar',
            yAxisIndex: 0,
            data: obj.series[0],
            barWidth: fontHandle(15),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
      chart.getZr().on('click', params => {
          let pointInPixel = [params.offsetX, params.offsetY]
          if (chart.containPixel('grid', pointInPixel)) {
            let xIndex = chart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]

            obj.series[0][xIndex] > 0 && this.openClickNumDialog(obj.xAxis.data[xIndex])
          }
      })
    },
    // 折线图
    clickCountChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['点击量'],
          bottom: fontHandle(10),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(14),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(50, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(14),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(14),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: '点击量',
            type: 'bar',
            yAxisIndex: 0,
            data: obj.series[0],
            barWidth: fontHandle(15),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },

    openHistoryDialog() {
      this.dialogHistoryVisible = true
      this.historyTargetDate = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      this.queryWaterDataChart()
    },
    queryWaterDataChart() {
      // 设备历史数据
      this.$axios({
      method: 'post',
      url: '/iot-admin/report/queryWaterDataChart',
      data: {boxId: this.boxId,timeEnd: moment(this.historyTargetDate[0]).format('YYYY-MM-DD'), timeStart: moment(this.historyTargetDate[1]).format('YYYY-MM-DD')}
    })
    .then((res) => {
      if (res.code === '00000') {
        this.dialogHistoryVisible = true
        this.historyTarget = JSON.parse(JSON.stringify(this.conditions))
        this.historyTarget.idName = 'history-target-chart'
        this.historyTarget.series.waterData.data = []
        this.historyTarget.series.oxData.data = []
        this.historyTarget.series.phData.data = []
        this.historyTarget.xAxis.data = []
        const dataArr = []
        res.data.forEach((e) => dataArr.push(...e.environment))
        
        dataArr.forEach((re) => {
            this.historyTarget.series.waterData.data.push(re.water)
            this.historyTarget.series.oxData.data.push(re.oxygen)
            this.historyTarget.series.phData.data.push(re.ph)
            this.historyTarget.xAxis.data.push(re.uploadTime)
        })
        console.log('dataArr', this.historyTarget.xAxis.data)
        this.historyTargetChart(this.historyTarget)
      }
    })
    .catch(() => {})
    },

    openClickNumDialog(month) {
      this.dialogChartVisible = true
      this.getLog(month)
      this.getYearLog()
    },
    getYearLog() {

      // 年点击数据
      this.$axios('/zhanjiang-admin/systemLog/year')
        .then((res) => {
          if (res.code === '00000') {
            // console.log('res', res)
            const data = res.data
            this.traceabilitySend.xAxis.data = Object.keys(data)
            this.traceabilitySend.series[0] = Object.values(data)
            this.aquaticTrendChart(this.traceabilitySend)
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    // 模板编译/挂载之后
    this.$axios('/zhanjiang-dataview/viewDigitalPark/queryInfo')
      .then((res) => {
        this.infoData = res.data
        let datearr = res.data.issueNumberList.map(
          (ele) => ({date: new Date(ele.name), name :ele.name, value: ele.value})
        )
        let datearr2 = res.data.queryCountByYearList.map(
          (ele) => ({date: new Date(ele.name), name :ele.name, value: ele.value})
        )
        const realarr = {}
        datearr.concat(datearr2).sort((a, b)=>a.date-b.date).map(ele => (realarr[ele.name]=ele.date))
        // console.log('realarr', realarr)

        let series0 = {}
        res.data.issueNumberList.map(
          (ele) => (series0[ele.name]= ele.value)
        )
        let series1 = {}
        res.data.queryCountByYearList.map(
          (ele) => (series1[ele.name]= ele.value)
        )
      })
      .catch(() => {})
      // this.getLog(this.$.formatDate(new Date(), 'yyyy-MM'))
    // 预警数据
    this.$axios('/zhanjiang-dataview/viewDigitalPark/queryWarning').then(
      (res) => {
        this.queryWarning = res.data
      }
    )

    // 获取视频列表信息
    this.$axios({
      method: 'get',
      url: '/zhanjiang-dataview/viewDigitalPark/queryVideoList',
      data: null,
    })
      .then((res) => {
        if (res.code === '00000') {
          const data = res.data
          if (data instanceof Array) {
            data.forEach((re, i) => {
              re.selected = i == 0 ? (true, (this.videoItem = re)) : false
            })
          }
          this.videoList = data
          // this.videoList = data.slice(1) || []
          // 初始化视频
          this.videoView(0)
        }
      })
      .catch(() => {})

    // 水体监测
    this.$axios('/zhanjiang-dataview/viewDigitalPark/queryWaterDataList')
      .then((res) => {
        // 上下线
        function calcPercent(val, quota) {
          let max = { oxygen: 30, ph: 14, water: 60 }[quota] // 获取指标的上限
          return (+val <= max ? (100 * (val / max)).toFixed(2) : +val) || 0
        }
        const data = JSON.parse(JSON.stringify(res.data))
        // 映射
        this.quotaList = data.map((re) => ({
          boxId: re.boxId,
          boxName: re.boxName,
          oxygen: re.oxygen,
          ph: re.ph,
          water: re.water,
          oxygen_percent: +calcPercent(re.oxygen, 'oxygen'),
          ph_percent: +calcPercent(re.ph, 'ph'),
          water_percent: +calcPercent(re.water, 'water'),
        }))

        console.log('quotaList1', this.quotaList)

        // 初始化网箱详情
        this.clickIdx(this.quotaList[0])
      })
      .catch(() => {})
      this.getYearLog()
  },
  created() {
    getWarning().then((res) => {
      // console.log('getWarning', res)
      this.warnTxt = res.warning.length > 0 ? res.warning[0].text : ''
    })
    getWeather('now', '101281001').then(
      (res) => {
        // console.log('nowWeather', res.now)
        this.nowWeather = res.now
      },
      (e) => {
        console.error('LocHandle error:' + e)
      }
    )
    getWeather('7d', '101281001').then(
      (res) => {
        // console.log('thrWeather', res.daily)
        this.thrWeather = res.daily
      },
      (e) => {
        console.error('LocHandle error:' + e)
      }
    )
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
}
</script>
<style lang="less" scoped>
@import './digital-park.less';

.no-data {
  display: flex;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 16px;

  align-items: center;
  justify-content: center;
}
.el-date-editor{
  border: none;
}
</style>
